<template>
  <div class="main" v-if="false">
      
  </div>
</template>

<script>

export default {
  data() {
    return {
      tl:'',
    }
  },
  mounted() {
    
  },
  methods: {
    
  }
};
</script>

<style lang="less" scoped>
.main {
  width: 100vw;
  height: 100vh;
  background-color: rgba(85, 81, 81, 0.411);
  z-index: 9999999;
  position: fixed;
  top: 0px;
  left: 0;
  .center {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
svg {
  display: none;
  position: relative;
  display: block;
  margin: 20px auto;
  width: 700px;
  max-height: 70vh;
}

#hippo {
  visibility: hidden;
}

#play {
  position: relative;
  display: block;
  width: 100px;
  margin: 20px auto;
  padding: 10px;
}
</style>
