import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function getToken() {
	const token = localStorage.getItem('token')
	console.log(token,'storetoken')
	return token || ''
}

export default new Vuex.Store({
	state: {
		id: localStorage.getItem("id"),
		name: "",
		img: "",
		money: "",
		mobile: "",
		loginState: false,
		loginStates: true,
		informState: true,
		id1: localStorage.getItem("csgoNum"),
		websockReadyState: 2,
		token: getToken(),
		loading: false
	},
	mutations: {
		openLoading(state,value) {
			state.loading = value
		},
		closeLoading(state,value) {
			state.loading = value
		},
		getId(state, obj) {
			state.id = obj.id;
			state.name = obj.name;
			state.img = obj.img;
			state.money = obj.total_amount;
		},
		getMoney(state, money) {
			state.money = money;
		},
		getLogin(state, loginState) {
			state.loginState = loginState;
		},
		getLogins(state, loginStates) {
			state.loginStates = loginStates;
			console.log('检查')
		},
		getInform(state, informState) {
			state.informState = informState;
		},
		getWebsock(state, websockReadyState) {
			state.websockReadyState = websockReadyState;
		},
		setMobile(state, value) {
			state.mobile = value
		},
		setUserToken(state, token) {
			localStorage.setItem('token', token)
			state.token = token
		},
		loginOut(state) {
			var loginChecked = localStorage.getItem("loginChecked");
			if(loginChecked){
				var account = localStorage.getItem("csgoNum");
				var password = localStorage.getItem("csgoPass");
			}
			localStorage.clear();
			if(loginChecked){
				localStorage.setItem("csgoNum",account);
				localStorage.setItem("csgoPass",password);
				localStorage.setItem("loginChecked",loginChecked);
			}
			state.token = '';
		}
	},
	actions: {
		
	},
	modules: {}
})

//export default store