<template>
	<div class="base-header">
		<div class="nav2">
			<ul>
				<li v-for="(item, index) in menu" :key="index" @click="goMenu(item.id, item.path)" :class="{
            activeli: $route.name == item.paths,
          }">
		  <img :src="item.img1" alt="" v-if="$route.name == item.paths">
		  <img :src="item.img2" alt="" v-else>
					<span :class="{
            active: $route.name == item.paths,
          }">{{ item.value }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: "base-header",
		data() {
			return {
				menu: [{
						id: 1,
						img1: require("@/assets/img/nav2/box2.png"),
						img2: require("@/assets/img/nav2/box.png"),
						selState: true,
						path: "Index",
						paths: "Index",
						child: ["Index", "Openbox"],
						value: "首页",
					},
					{
						id: 2,
						img1: require("@/assets/img/nav2/battle2.png"),
						img2: require("@/assets/img/nav2/battle.png"),
						selState: false,
						path: "Lucky",
						paths: "Lucky",
						child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
						value: "决战沙城",
					},
					{
						id: 3,
						img1: require("@/assets/img/nav2/roll2.png"),
						img2: require("@/assets/img/nav2/roll.png"),
						selState: false,
						path: "Arena",
						paths: "Arena",
						child: ["Arena", "ArenaRoom"],
						value: "ROLL房",
					},
					{
						id: 4,
						img1: require("@/assets/img/nav2/lucky2.png"),
						img2: require("@/assets/img/nav2/lucky.png"),
						selState: false,
						path: "OrnamentOpen",
						paths: "OrnamentOpen",
						child: [
							"Ornament",
							"OrnamentOpen",
							"OrnamentHistory",
							"OrnamentHistory2",
						],
						value: "幸运饰品",
					},
					{
						id: 5,
						img1: require("@/assets/img/nav2/store2.png"),
						img2: require("@/assets/img/nav2/store.png"),
						selState: false,
						path: "Roll",
						paths: "Roll",
						child: ["Roll"],
						value: "饰品商城",
					},
					// {
					// 	id: 6,
					// 	img1: require("@/assets/img/nav2/福利中心2.png"),
					// 	img2: require("@/assets/img/nav2/福利中心.png"),
					// 	selState: false,
					// 	path: "task-center",
					// 	paths: "TaskCenter",
					// 	child: ["task-center"],
					// 	value: "福利中心",
					// },
					{
						id: 7,
						img1: require("@/assets/img/nav2/mianfeifuli2.png"),
						img2: require("@/assets/img/nav2/mianfeifuli.png"),
						selState: false,
						path: "Activity",
						paths: "Activity",
						child: ["Activity","Openbox"],
						value: "免费福利",
					},
					{
						id: 8,
						img1: require("@/assets/img/nav2/taihuan2.png"),
						img2: require("@/assets/img/nav2/taihuan.png"),
						selState: false,
						path: "Tai",
						paths: "Tai",
						child: ["Tai","Openbox"],
						value: "汰换",
					},
					{
						id: 9,
						img1: require("@/assets/img/nav2/shijianbox2.png"),
						img2: require("@/assets/img/nav2/shijianbox.png"),
						selState: false,
						path: "TimeBox",
						paths: "TimeBox",
						child: ["TimeBox","Openbox"],
						value: "时间盲盒",
					},
					// {
					// 	id: 6,
					// 	img1: require("@/assets/img/nav2/b6.png"),
					// 	img2: require("@/assets/img/nav2/b66.png"),
					// 	selState: false,
					// 	path: "Mes",
					// 	child: ["Mes"],
					// 	value: "活动推广",
					// },
					// {
					// 	id: 7,
					// 	img1: require("@/assets/img/nav2/b8.png"),
					// 	img2: require("@/assets/img/nav2/b88.png"),
					// 	selState: false,
					// 	path: "Mes",
					// 	child: ["Mes"],
					// 	value: "饰品背包",
					// },
				],
			};
		},
		mounted() {
			console.log(this.$route)
		},
		methods: {
			goMenu(id, path) {
				this.$emit("goMenu", id, path);
			},
		},
	};
</script>

<style lang="less" scoped>
	.base-header {
		padding-left: 50px;
		    
		.nav2 {
			height: 42px;

			ul {
				display: flex;

				.active {
					color: #FF1F71;
				}
				.activeli{
					position: relative;
					&::after{
						position: absolute;
						bottom: 0px;
						left: 50%;
						transform: translate(-50%);
						content: '';
						width: 50px;
						height: 2px;
						background: linear-gradient(98deg, rgb(255, 47, 47) 2.49%, rgb(159, 36, 255) 92.88%);
						display: block;
					}
				}
				li {
					float: left;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100px;
					text-align: center;
					cursor: pointer;
					color: #fff;
					font-size: 15px;
					line-height: 38px;
					margin-left: 10px;
					white-space: nowrap;
					font-weight: bold;
					img{
						width: 17px;
						height: auto;
						margin-right: 5px;
					}
					&:hover{
						color: #FF1F71;
					}
					.icon{
						width: 20px;
						height: 20px;
						background-image: url(../../../assets/img/nav2/b11.png);
						background-size: 100% 100%;
					}
				}
			}
		}
	}
</style>